










































































































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ContractSavings from '@/vue-app/components/ContractSavings.vue';
import ModalPrepareDocumentsWealthViewModel from '@/vue-app/view-models/components/flagship/flagship-link-goals/steps-wealth/modal-prepare-documents-wealth-view-model';

@Component({
  name: 'ModalPrepareDocumentsWealth',
  components: { ContractSavings },
})
export default class ModalPrepareDocumentsWealth extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('link', { type: Boolean, required: false, default: false })
  synced_link!: boolean;

  private readonly modal_prepare_documents_wealth_view_model = Vue.observable(
    new ModalPrepareDocumentsWealthViewModel(),
  );

  closeModal() {
    this.synced_is_open = false;
  }
}
